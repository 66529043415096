<template>
  <div class="mb-12">
    <base-main-title class="mt-3" :title="'تواصل معنا'" />
    <v-fade-transition leave-absolute>
      <base-card color="rgba(203, 170, 92, 0.51)" class="pa-3">
        <v-container>
          <v-row class="pa-16">
            <v-col cols="12" md="4">
              <!-- <base-subheading class="mb-3"> اشتراك </base-subheading> -->
              <p class="mt-6">
                ادخل البريد الالكتروني للاشتراك ليصلك اشعار في المحتوى الجديد
              </p>
              <v-row class="pa-2">
                <v-responsive class="overflow-visible ml-2" max-width="400">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="email"
                      label="البريد الالكتروني"
                      solo
                      :rules="emailRules"
                      required
                    />
                  </v-form>
                </v-responsive>

                <v-btn
                  :block="$vuetify.breakpoint.xsOnly"
                  class="ma-0"
                  color="secondary"
                  style="height: 48px"
                  @click="postSubscribe()"
                  :loading="subscribeLoading"
                >
                  اشتراك
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" md="4">
              <!-- <base-subheading>تواصل معنا</base-subheading> -->

              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-avatar color="primary">
                    <v-icon class="elevation-4" dark>
                      mdi-email-outline
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-title>البريد الالكتروني</v-list-item-title>

                  <v-list-item-subtitle>
                    center.alqamar@gmail.com
                  </v-list-item-subtitle>
                </v-list-item>

                <v-list-item @click="openGoogleMap()">
                  <v-list-item-avatar color="primary">
                    <v-icon class="elevation-4" dark> mdi-map-marker </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>العنوان</v-list-item-title>

                  <v-list-item-subtitle>
                    النجف الاشرف,الحنانة,مجمع المرتضى
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar color="primary">
                    <v-icon class="elevation-4" dark> mdi-phone </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-title>الموبايل</v-list-item-title>

                  <v-list-item-subtitle> 07602421241 </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
            <DownloadApp />
            <!-- <v-col cols="12" md="4">
              <div
                class="d-flex justify-space-around align-center bg-grey-lighten-4"
              >
                <div class="ma-4">
                  <div class="text-subtitle-2 mb-2">حمل التطبيق من متجر ابل</div>
                  <v-img
                    class="bg-white"
                    width="150"
                    max-height="125"
                    :aspect-ratio="4"
                    :src="require('@/assets/apple-badge.png')"
                  ></v-img>
                </div>

                <div class="ma-4">
                  <div class="text-subtitle-2 mb-2">حمل التطبيق من متجر كوكل</div>
                  <v-img
                    class="bg-white"
                    width="150"
                    
                    :aspect-ratio="4"
                    :src="require('@/assets/google-badge.png')"
                  ></v-img>
                </div>
              </div>
            </v-col> -->
          </v-row>
        </v-container> </base-card
    ></v-fade-transition>
    <v-snackbar
      v-if="subscribe"
      v-model="subscribe.status"
      :timeout="timeout"
      color="primary"
      top
    >
      {{ subscribe.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="subscribe.status = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { GOOGLEMAP_URL } from "@/constants/index";

export default {
  name: "HomeContact",
  components: {
      DownloadApp: () => import('@/components/DownloadApp'),
    },
  data: () => ({
    valid: false,
    email: "",
    emailRules: [
      (v) => !!v || "البريد الالكتروني مطلوب",
      (v) => /.+@.+/.test(v) || "يجب ان البريد الالكتروني صحيح",
    ],
    timeout: 5000,
  }),
  computed: {
    subscribeLoading() {
      return this.$store.getters.subscribeLoading;
    },
    subscribe() {
      if (
        this.$store.getters.subscribe !== null &&
        this.$store.getters.subscribe !== undefined
      )
        return this.$store.getters.subscribe;
      else return null;
    },
  },
  methods: {
    postSubscribe() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$store.dispatch("postSubscribe", this.email);
      }
    },

    openGoogleMap() {
      window.open(GOOGLEMAP_URL, "_blank");
    },
  },
};
</script>
